export default function Faq() {
  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-4xl font-black mb-12 text-center">Frequently Asked Questions</h2>
      <div>
        <div className="">
          <details className="mb-4">
            <summary className="font-black bg-pink-500 text-lg rounded-md py-2 px-4 shadow">
              What are NFTs?
            </summary>
            <div className="p-4 leading-relaxed font-gray-900 text-md">
              NFTs, non-fungible tokens, are digital works of art and other items that can be bought and sold using this blockchain technology. Owning an NFT is like owning a painting - it belongs to you and you only but instead of owning it physically you own it virtually.
          </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-pink-500 text-lg rounded-md py-2 px-4 shadow">
              What does „minting“ mean?
            </summary>
            <div className="p-4 leading-relaxed font-gray-900 text-md">
              Minting an NFT means your digital asset will be kind of coded in the blockchain and have a specific codification so your NFT is one of kind and no one can copy it and it's also available to transaction.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-pink-500 text-lg rounded-md py-2 px-4 shadow">
              Why should I mint LabKitten?
            </summary>
            <div className="p-4 leading-relaxed font-gray-900 text-md">
              But why not!?
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-pink-500 text-lg rounded-md py-2 px-4 shadow">
              What does airdrop mean?
            </summary>
            <div className="p-4 leading-relaxed font-gray-900 text-md">
            Airdrops are NFT giveaways to reward early adopters of projects and promote their uses.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-pink-500 text-lg rounded-md py-2 px-4 shadow">
              Where can I view my NFTs?
            </summary>
            <div className="p-4 leading-relaxed font-gray-900 text-md">
              Once you mint you LabKittens, you just need to log into your OpenSea account to view your collection.
            </div>
          </details>
        </div>
      </div>
    </div>
  )
}
