import data from "../rarity.json";
import React from "react";

const Rarity = () => {
  const keys = Object.keys(data)
  keys.forEach((k) => {
    data[k].sort((a, b) => {
      const f = a.occurrence.replace(/(^\d+)(.+$)/i,'$1')
      const s = b.occurrence.replace(/(^\d+)(.+$)/i,'$1')
      return f - s
    })
  })
  console.log(data)
  return (
    <>
      <h2 className="text-4xl font-black mb-12 text-center">Rarity</h2>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
        { keys.map((r) => (
          <div className="border-l border-r border-b border-pink-500 rounded-lg overflow-hidden shadow-lg" key={r}>
            <div className="uppercase font-black bg-pink-500 px-6 py-2">{r}</div>
            <ul className="px-6 py-4">
            {data[r].map((i, k) => (
              <li className="text-gray-500 mb-1" key={k}><span className="font-semibold text-gray-900">{i.trait}</span> - {i.occurrence}</li>
            ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  )
}

export default Rarity
