import Faq from './Faq'

function Story () {
  return (
    <>
      <div className="lg:flex block gap-12 max-w-5xl mx-auto items-center mb-32">
        <div className="lg:w-6/12 w-full lg:mb-0 mb-12">
          <h2 className="text-4xl font-black mb-8">Story</h2>
          <p className="mb-4">The crazy scientist who’s keen on animal experimentation has a vivarium with 2000 kittens. Unfortunately he is not so keen on caring of them. Actually he does not even like cats. Please help kitties to escape from the laboratory! You can set them free by buying them. Please remember that random acts of kindness eventually save the World. </p>
          <p className="mb-4">LabKittens is a collection of 2000 unique algorithmically generated pieces of collectible art living on the Ethereum-compatible Polygon Network.</p>
          <p className="mb-4">Every LabKitten is unique. But of course, where’s 2000 of them, some are even more rare than others.</p>
          <p className="mb-4">Having a LabKitten guarantees you copyright and commercial usage, as well as access to future airdrops.</p>
        </div>
        <div className="lg:w-6/12 w-full">
          <img src="../mosaic.png" className="w-full rounded-lg shadow"/>
        </div>
      </div>
      <Faq />
    </>
  )
}
export default Story
