import React from "react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Story from "./components/Story"
import Mint from './components/Mint'
import Header from './components/Header'
import Footer from './components/Footer'
import Roadmap from "./components/Roadmap"
import Contact from "./components/Contact"
import Rarity from "./components/Rarity"

function App() {
  return (
    <Router>
      <div>
        <Header />
        <div className="container mx-auto px-6 py-24">
          <Routes>
            <Route path="/" element={<Story />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/rarity" element={<Rarity />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
