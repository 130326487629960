import React from "react"
import {
  Timeline,
  Events,
  TextEvent,
} from '@merc/react-timeline'

const Roadmap = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <h2 className="text-4xl font-black mb-8 text-center">Roadmap</h2>
      <Timeline>
        <Events>
          <TextEvent date="January 2022" text="We release **2000 LabKittens**." />
          <TextEvent date="12. january 2022" text="We'll airdrop **1 LabKitten** to random lucky Twitter follower." />
          <TextEvent date="13. january 2022" text="We **reveal the kittens!**." />
          <TextEvent date="17. january 2022" text="We'll airdrop **1 LabKitten** to random lucky Twitter follower." />
          <TextEvent date="19. january 2022" text="We'll airdrop **1 LabKitten** to random lucky Twitter follower." />
          <TextEvent date="24. january 2022" text="We'll airdrop **1 LabKitten** to random lucky Twitter follower." />
          <TextEvent date="26. january 2022" text="We'll airdrop **1 LabKitten** to random lucky Twitter follower." />
          <TextEvent date="10% sold" text="After 10% of LabKittens are sold, we’ll airdrop **5 LabKittens** to random lucky holders." />
          <TextEvent date="50% sold" text="After 50% of LabKittens are sold, we’ll airdrop **10 LabKittens** to random lucky holders." />
          <TextEvent date="100% sold" text="When 100% of LabKittens are sold – official collection on **OpenSea for sale**." />
        </Events>
      </Timeline>
    </div>
  )
}

export default Roadmap
